@import '../app.scss';

.projects {
  @extend .global-page;

  .subtitle {
    font-size: 1.2rem;
    color: $grey;
  }

  .content {
    display: flex;
    flex-direction: row;
    height: 65vh;
    width: 100%;
    padding-top: 10vh;
    overflow-x: auto;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    @media screen and (max-width: map-get($breakpoints, 'mobile-max')) {
      height: auto;
    }
  }
}