@import '../app.scss';

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: $navbar-height;

  &__logo {
    width: 20%;

    .home-link{
      img{
        max-height: 5vh;
      }
    }


    .title {
      color: $white;
      text-transform: uppercase;
      font-weight: 900;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        font-size: 1.2rem;
      }
    }
  }

  &__links {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $grey;
    height: 5vh;
    border-radius: 50px;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      display: none;
    }

    .link {
      margin: 0 20px 0 20px;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      color: $white;
      font-weight: 600;
      padding: 5px;
      border-radius: 50px;
      padding: 5px 10px 5px 10px;

      &.active{
        background-color: $white;
        color: $grey;
      }

      &:hover{
        background-color: $white;
        color: $grey;
      }
    }
  }

  &__burger {
    display: none;
    vertical-align: middle;
    color: $grey;

    p {
      margin: 0;
      font-size: 1.2rem;
    }

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      display: block;
    }
  }

  &__mobile {
    display: none;

    &__active {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $white;
      height: 100vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      overflow-x: hidden;
    }

    .link {
      margin: 20px 0 20px 0;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      color: $grey;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
}