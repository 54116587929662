@import '../app.scss';

#notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;

  h1 {
    // margin-top: 15vh;
    text-transform: uppercase;
    font-size: 4rem;
    color: $grey;
  }

  h2 {
    color: $grey;
    font-weight: 400;
    font-size: 1.8rem;
  }

  p {
    color: $grey;
    font-size: 1.2rem;
    margin-top: 2rem;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}