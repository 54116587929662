@import '../app.scss';

.about {
  @extend .global-page;

  &__content{
    display: flex;
    flex-direction: row;
    
    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      flex-direction: column;
    }

    &__left, &__right{
      width: 50%;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        width: 100%;
      }
    }

    &__left{
      padding-top: 5vh;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        padding-top: 2vh;
      }
    }

    &__right{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        padding-top: 5vh;
      }

      img{
        max-width: 80%;
        border-radius: 50px;
      }
    }
  }
}