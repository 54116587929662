@import './colors.scss';
@import './fonts.scss';

// Responsive breakpoints
// Those breakpoint are specific to the project
// We only have 3 use cases : mobile, tablet and desktop
// Mobile < 1024px < Tablet < 1224px < Desktop
$breakpoints: (
  'ultra-small': '500px',
  'mobile-max': '992px',
  'tablet-min': '1023px',
  'tablet-max': '1224px',
);

$navbar-height: 80px;
$page-height: calc(100vh - 80px);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
  color: $grey;
}

p {
  font-size: 21px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    font-size: 18px;
  }
}

.global-page{
  min-height: $page-height;
  padding-top: 10vh;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    height: auto;
    padding-top: 5vh;
  }
}

.h1 {
  font-size: 62px;
  font-weight: 900;
  text-transform: uppercase;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    font-size: 31px;
  }
}

.h2 {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 20px;
  text-transform: uppercase;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    font-size: 24px;
  }
}

.h3 {
  font-size: 24px;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 20px;
  text-transform: uppercase;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    font-size: 18px;
  }
}

form {
  .form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    input,
    textarea {
      padding: 1rem;
      border: 1px solid $grey;
      border-radius: 20px;
      margin-top: 0.5rem;

      &[placeholder] {
        color: $grey;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  button[type="submit"] {
    background-color: $grey;
    color: $white;
    border: none;
    padding: 1rem;
    border-radius: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 1rem;
    width: 50%;
    transition: .3s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $black;
    }

    &[disabled] {
      background-color: $white;
      color: $grey;
      border: 1px solid $grey;
      cursor: not-allowed;
    }
  }
}

.test {
  margin-bottom: 50vh;
}

@media screen and (max-width: 992px) {
  body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}