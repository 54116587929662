@import '../app.scss';

#contact {
  height: 90vh;
  padding-top: 10vh;

  h1 {
    font-size: 4rem;
    font-weight: 800;
    color: $grey;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 1.2rem;
    color: $grey;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left {
      width: 50%;

      .error {
        color: $red;
        font-size: 1.2rem;
        font-weight: 700;
        margin-top: 1rem;
      }

      .success {
        color: $green;
        font-size: 1.2rem;
        font-weight: 700;
        margin-top: 1rem;
      }
    }

    .right {
      width: 50%;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 2rem;
        font-weight: 800;
        color: $grey;
        text-transform: uppercase;
        margin-bottom: 2vh;
      }

      .socials {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        .link {
          margin-bottom: 1rem;
          color: $grey;
          font-size: 1.2rem;
        }
      }

      .number-container {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        .number {
          color: $grey;
          font-size: 2.5rem;
          font-weight: 800;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  #contact {
    height: auto;

    h1 {
      font-size: 2.5rem;
    }

    .subtitle {
      font-size: 1rem;
    }

    .content {
      flex-direction: column;

      .left {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .right {
        width: 100%;
        margin-top: 5vh;

        .socials {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 2rem;

        }

        .title {
          font-size: 1.5rem;
          text-align: center;
        }

        .number-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          .number {
            font-size: 2rem;
            text-decoration: none;
          }
        }
      }
    }
  }
}