@import '../app.scss';

.project {
  display: flex;
  flex-direction: column;
  min-width: 425px;
  width: 425px;
  height: 350px;
  background-color: $white;
  border-radius: 20px;
  margin-right: 60px;
  border: 1px solid $grey;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    margin-right: 0;
    margin-bottom: 40px;
  }

  @media screen and (max-width: map-get($breakpoints, 'ultra-small')) {
    width: 100%;
    min-width: auto;
  }
  
  &__header{
    position: relative;
    border-bottom: 1px solid $grey;
    height: 45%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px 20px 0 0;
      object-fit: cover;
    }
    
    &:hover{
      cursor: pointer;
      
      &::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba($grey, .5);
        border-radius: 20px 20px 0 0;
      }
      
      &::after{
        position: absolute;
        content: 'Voir le projet >';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
      }
    }
  }

  &__body{
    padding: 5px 10px;

    &__title{
      font-size: 1.8rem;
      font-weight: 800;
      color: $grey;
      margin-bottom: 5px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &__technos{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__badge{
        padding: 5px 10px;
        border-radius: 20px;
        background-color: $grey;
        color: $white;
        font-size: .9rem;
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }

}