@import '../app.scss';

.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  

  img{
    max-height: 7vh;
  }
}