@import '../app.scss';

.services {
  height: 90vh;
  padding-top: 10vh;


  .subtitle {
    font-size: 1.2rem;
    color: $grey;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .left {
      width: 50%;
      padding-top: 5vh;

      p {
        font-size: 1.2rem;
        color: $grey;
      }

      .link-container {
        font-weight: 600;
        padding-top: 10vh;

        p {
          color: $grey;
        }


        .link {
          margin-top: 2vh;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 60vh;
      
      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        height: auto;
        margin-top: 5vh;
        margin-bottom: 5vh;
      }

      .service {
        h3 {
          font-size: 2rem;
          font-weight: 600;
          color: $grey;
          text-transform: uppercase;

          @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
            font-size: 1.5rem;
          }
        }

        p {
          font-size: 1.2rem;
          color: $grey;
          margin-top: 10px;
        }
      }
    }

    .left, .right{
      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        width: 100%;
      }
    }
  }
}