@import '../app.scss';


.cta {
  padding: 10px 40px;
  font-size: 1.2rem;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: $grey;
  color: $white;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}