@import '../app.scss';

.home {
  @extend .global-page;

  &__content{
    text-align: center;
    margin-top: 15vh;

    .title{
      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        font-size: 3rem;
      }
    }

    .subtitle{
      font-weight: normal;

      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        font-size: 1.2rem;
      }
    }

    &__cta{
      margin-top: 10vh;
      
      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        margin-top: 5vh;
      }
    }
    
    &__catch-phrase{
      margin-top: 10vh;
      
      @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
        margin-top: 5vh;
      }
      

      .catch-phrase{
        font-size: 1.5rem;

        @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
          font-size: 1.2rem;
        }
      }
    }
  }
}