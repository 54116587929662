@import '../app.scss';

#layout {
  background:
    linear-gradient(90deg, $white (50px - 1.5px), transparent 1%) center,
    linear-gradient($white (50px - 1.5px), transparent 1%) center,
    rgba($black, .3);
  background-size: 50px 50px;
  min-height: 100vh;
  // height: auto;
  padding: 0 5rem 0 5rem;

  @media screen and (max-width: map-get($breakpoints, 'tablet-max')) {
    padding: 0 20px 20px 20px;
    height: auto;
    min-height: 100vh;
  }
}